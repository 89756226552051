import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Lightning from "../../../client_customizations/assets/images/icons/LightningIcon.svg";
import Gasoline from "../../../client_customizations/assets/images/icons/gasIcon.svg";
import Hybrid from "../../../client_customizations/assets/images/icons/Hybrid.svg";
import getCarCostOfOwnership from "../../../functions/vehicle/CostOfOwnership/getCarCostOfOwnership";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { FormatCarName, FormatAsDollars } from "../../../utils/Helpers/Format";
import "./MonthlyFuelComparison.scss";


const FuelCostGraphic = ({ variant, cost, vehicleType }) => (
  <div className="fuel-graphic">
    <div
      className="fuel-graphic-bubbles"
      style={{
        height: variant === "large" ? 150 : variant === "medium" ? 110 : 80,
        width: variant === "large" ? 150 : variant === "medium" ? 110 : 80,
        backgroundColor:
          vehicleType === "BEV" || vehicleType === "PHEV"
          ? "orange"
            : "#253748",
      }}
    >
      {vehicleType === "BEV" ? (
        <img src={Lightning} alt="" />
      ) : vehicleType === "PHEV" ? (
        <img src={Hybrid} alt="" />
      ) : (
        <img src={Gasoline} alt="" />
      )}
      <p
        className="fuel-graphic-text"
        style={
          vehicleType === "BEV" || vehicleType === "PHEV"
            ? { color: "black" }
            : { color: "white" }
        }
      >
        {FormatAsDollars(cost)}
      </p>
    </div>
  </div>
);

export default function MonthlyFuelComparison({
  cars,
  comparison,
  insuranceData,
  maintenanceData,
}) {
  const userPrefs = useContext(UserPrefsContext);
   const intl = useIntl();

  const carsCosts = cars.map((car) => {
    return getCarCostOfOwnership(
      car,
      userPrefs,
      insuranceData,
      maintenanceData
    );
  });
  const vehicle1Fuel =
    carsCosts[0].electricity.perMonth + carsCosts[0].gasoline.perMonth;
  const vehicle2Fuel =
    carsCosts[1].electricity.perMonth + carsCosts[1].gasoline.perMonth;
  const vehicle3Fuel =
    cars.length > 2
      ? carsCosts[2].electricity.perMonth + carsCosts[2].gasoline.perMonth
      : null;
  const monthlyFuelCostDelta = vehicle1Fuel - vehicle2Fuel;

  const sortedCarCosts = [...carsCosts].sort((carCost1, carCost2) => {
    let cost1 = carCost1.electricity.perMonth + carCost1.gasoline.perMonth;
    let cost2 = carCost2.electricity.perMonth + carCost2.gasoline.perMonth;
    return cost1 - cost2;
  });

  return (
    <div className="costOfOwnership input-well">
      <div className="graph-title">
        <div className="graph-box">
          <div
            className={
              comparison ? "graph-sub-title margin" : "graph-title margin"
            }
          >
            {cars.length > 2 ? (
              <FormattedMessage
                id="graph.monthlyCostToFill.subTitleThreeCars"
                defaultMessage="The {carName} is least expensive to fill up monthly"
                description="Three Graph Sub Title"
                values={{
                  carName: FormatCarName(sortedCarCosts[0].car),
                }}
              />
            ) : monthlyFuelCostDelta > 0 ? (
              <FormattedMessage
                id="graph.monthlyCostToFill.subTitle"
                defaultMessage="The {carName} is {style} {costDeltaText} to fill up monthly"
                description="Graph Sub Title"
                values={{
                  carName: FormatCarName(cars[0]),
                  style: (
                    <strong className="strong-green">
                      {FormatAsDollars(Math.abs(monthlyFuelCostDelta))}
                    </strong>
                  ),
                  costDeltaText: intl.formatMessage({
                    id: "vehicle.moreExpensive",
                    defaultMessage: "more expensive",
                  }),
                }}
              />
            ) : (
              <FormattedMessage
                id="graph.monthlyCostToFill.subTitle"
                defaultMessage="The {carName} is {style} {costDeltaText} to fill up monthly"
                description="Graph Sub Title"
                values={{
                  carName: FormatCarName(cars[0]),
                  style: (
                    <strong className="strong-green">
                      {FormatAsDollars(Math.abs(monthlyFuelCostDelta))}
                    </strong>
                  ),
                  costDeltaText: intl.formatMessage({
                    id: "graph.cheaper",
                    defaultMessage: "cheaper",
                  }),
                }}
              />
            )}
          </div>
          <div
            className="fuel-cost-boxes"
            style={cars.length > 2 ? { width: "130%" } : { width: "100%" }}
          >
            <div className="fuel-cost-box">
              <FuelCostGraphic
                variant={
                  vehicle1Fuel > 300
                    ? "large"
                    : vehicle1Fuel > 200
                    ? "medium"
                    : "small"
                }
                vehicleType={cars[0]["fuel"]}
                cost={vehicle1Fuel}
              />
              <div className="bubble-title" mt={1.5}>
                {FormatCarName(cars[0])}
              </div>
            </div>

            <div className="fuel-cost-box">
              <FuelCostGraphic
                variant={
                  vehicle2Fuel > 300
                    ? "large"
                    : vehicle2Fuel > 200
                    ? "medium"
                    : "small"
                }
                vehicleType={cars[1]["fuel"]}
                cost={vehicle2Fuel}
              />
              <div className="bubble-title" mt={1.5} fontSize="0.875rem">
                {FormatCarName(cars[1])}
              </div>
            </div>

            {cars.length > 2 ? (
              <div className="fuel-cost-box">
                <FuelCostGraphic
                  variant={
                    vehicle3Fuel > 300
                      ? "large"
                      : vehicle3Fuel > 200
                      ? "medium"
                      : "small"
                  }
                  vehicleType={cars[2]["fuel"]}
                  cost={vehicle3Fuel}
                />
                <div className="bubble-title" mt={1.5} fontSize="0.875rem">
                  {FormatCarName(cars[2])}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
