import fetchWrapper from "../utils/fetch/fetchWrapper";
const fetchIncentives = async params => {
  // Remove null params
  Object.keys(params).forEach(key => params[key] == null && delete params[key]);

  let url = new URL(`${process.env.REACT_APP_API_HOST}/incentives`);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const json = await fetchWrapper(url, {
    method: "GET",
  });
  const incentives = json.incentives;
  return incentives.filter(
    (incentive) =>
      incentive.grantor !== "Poudre Valley R  E  A, Inc" &&
      incentive.grantor !== "Xcel Energy of Colorado" &&
      incentive.grantor !== "MPEI" &&
       incentive.grantor !== "United Power, Inc." &&
       incentive.grantor !== "United Power" &&
       incentive.grantor !== "Mountain Parks Electric, Inc." &&
     incentive.grantor !== "Poudre Valley REA"
  );
};

export default fetchIncentives;
