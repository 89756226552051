import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import { Link } from "react-router-dom";
import "./EVCard.scss";

import RenderItem from "../RenderItem/RenderItem";
import VehicleImage from "./../VehicleImage/VehicleImage";

// import calcMatchScore from "../../functions/vehicle/MatchScore/calcMatchScore";
import getPaymentDetails from "../../functions/vehicle/getPaymentDetails";
import { FormatAsDollars, FormatCarModelAndTrim } from "./../../utils/Helpers/Format";

import IconBEV from "./../../client_customizations/assets/images/icons/Electric.svg";
import IconPHEV from "./../../client_customizations/assets/images/icons/PHEV.svg";
import IconLocal from "./../../client_customizations/assets/images/icons/icon-local.png";
import isBEV from "../../functions/vehicle/isBEV";
import isPHEV from "../../functions/vehicle/isPHEV";
import { FormattedMessage, useIntl } from "react-intl";

const EVCard = ({ ev, hasEvDetails, hasIncentivesAndMatchScore, hasLinkToEv, hasLocallyAvailableHidden }) => {
     const intl = useIntl();
     const userPrefs = useContext(UserPrefsContext);

     if (!ev) return null;

     let evImgs = ev.images ? ev.images.map((n) => (n.url_thumbnail == null ? [] : n.url_full == null ? [] : [n])) : [];

     //Flatten evImgs into a single array
     const evImgSrc = [].concat.apply([], evImgs);

     const imgSrc = ev.images ? (ev.images.length >= 0 ? evImgSrc[0] : "") : "";
     const msrp = ev.msrp ? ev.msrp : 0;
     const paymentDetails = getPaymentDetails(ev, userPrefs);
     const afterIncentives = paymentDetails.afterIncentives ? paymentDetails.afterIncentives : 0;

     const renderLocalIcon = ev.locally_available ? (
          <span className="badge-locally-available">
               <img alt="Available Locally" src={IconLocal} />
          </span>
     ) : null;

     const renderFuelTypeBadge = isBEV(ev) ? (
          <span className="badge-fuel-type">
               <img alt="All-Electric" src={IconBEV} />
          </span>
     ) : isPHEV(ev) ? (
          <span className="badge-fuel-type">
               <img style={{ width: "35px" }} alt="Hybrid" src={IconPHEV} />
          </span>
     ) : null;

     let altText = "Go to " + (ev.make + " " + ev.model + " " + ev.trim).toString().trim();

     const renderEVDetails = hasEvDetails ? (
          <div className="renderRowOfData">
               <p>
                    <small>
                         <FormattedMessage
                              id="evCard.electricRange"
                              defaultMessage="Electric Range"
                              description="Electric Range"
                         />
                    </small>
                    <span>
                         {ev.electric_range}{" "}
                         {process.env.REACT_APP_METRIC_SYSTEM
                              ? intl.formatMessage({
                                     id: "vehicle.kilometersShort",
                                     defaultMessage: "km",
                                })
                              : intl.formatMessage({
                                     id: "vehicle.miles",
                                     defaultMessage: "miles",
                                })}
                    </span>
               </p>
               {isPHEV(ev) && (
                    <p>
                         <small>
                              <FormattedMessage
                                   id="evCard.totalRange"
                                   defaultMessage="Total Range"
                                   description="Total Range"
                              />
                         </small>
                         <span>
                              {ev.total_range}{" "}
                              {process.env.REACT_APP_METRIC_SYSTEM
                                   ? intl.formatMessage({
                                          id: "kilometersShort",
                                          defaultMessage: "km",
                                     })
                                   : intl.formatMessage({
                                          id: "vehicle.miles",
                                          defaultMessage: "miles",
                                     })}
                         </span>
                    </p>
               )}
               <p>
                    <small>
                         <FormattedMessage id="vehicle.msrp" defaultMessage="MSRP" description="MSRP" />
                    </small>
                    <span>{FormatAsDollars(msrp)}</span>
               </p>
               {!isPHEV(ev) && (
                    <p>
                         <small>&nbsp;</small>
                         <span />
                    </p>
               )}
          </div>
     ) : null;

     const renderCardBottom = hasIncentivesAndMatchScore ? (
          <div className="EVCardBottom">
               <RenderItem
                    title={intl.formatMessage({ id: "vehicle.afterIncentives", defaultMessage: "AFTER INCENTIVES" })}
                    value={FormatAsDollars(afterIncentives)}
               />
          </div>
     ) : (
          hasLinkToEv && (
               <div className="EVCardBottomWithLink">
                    <Link
                         to={`${"/vehicles/" + ev.handle}`}
                         className="btn btn-ae"
                         target="_blank"
                         style={{ outline: "none", whiteSpace: "nowrap" }}
                    >
                         <FormattedMessage
                              id="evCard.seeElectricVehicle"
                              defaultMessage="SEE ELECTRIC VEHICLE"
                              description="SEE ELECTRIC VEHICLE"
                         />
                    </Link>
               </div>
          )
     );

     return (
          <>
               <div className="EVCardTop">
                    {!hasLocallyAvailableHidden && renderLocalIcon}
                    {renderFuelTypeBadge}
                    <p className="h2" style={{ maxWidth: "70%" }}>
                         {ev.make}
                    </p>
                    <p className="h3 mt-1">{FormatCarModelAndTrim(ev)}</p>
                    <div className="text-center">
                         <VehicleImage image={imgSrc} size="thumb" alt={altText} />
                    </div>
                    {renderEVDetails}
               </div>
               {renderCardBottom}
          </>
     );
};

export default EVCard;

EVCard.propTypes = {
     ev: PropTypes.object,
     hasEvDetails: PropTypes.bool,
     hasIncentivesAndMatchScore: PropTypes.bool,
     hasLinkToEv: PropTypes.bool,
     hasLocallyAvailableHidden: PropTypes.bool,
};
