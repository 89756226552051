import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import ShowHideButton from "../ShowHideButton/ShowHideButton";
import { FormatAsDollars, FormatCarName } from "../../utils/Helpers/Format";
import calcTotalCostOfOwnership from "../../functions/vehicle/CostOfOwnership/calcTotalCostOfOwnership"
import { VehicleCost } from "../../functions/vehicle/CostOfOwnership/calcs";
import { SALES_TAX, ELECTRICITY_RATE_IN_DOLLARS_PER_KWH } from "../../client_customizations/data/assumptions/ASSUMPTIONS"
import getCarCostOfOwnership from "../../functions/vehicle/CostOfOwnership/getCarCostOfOwnership";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import times from "lodash/times";

import { FormattedMessage, useIntl } from 'react-intl';

// import "./BreakevenChart.css";

const CHART_JS_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        const value = FormatAsDollars(
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
        );
        const label = data.datasets[tooltipItem.datasetIndex].label || "";
        return " " + label + ": " + value;
      },
      title: function(tooltipItem) {
        return "year " + tooltipItem[0].xLabel;
      }
    }
  },
  scales: {
    x:{
        stacked: true
      },
    y: {
        ticks: {
          font: {
            weight: 700
          },
          beginAtZero: false,
          callback: function(value) {
            return FormatAsDollars(value);
          }
        }
      }
  },
  plugins: {
  legend: {
    position: "bottom",
    onClick: function(e) {
      e.stopPropagation();
    }
  },
    datalabels: {
      // chartjs-plugin-datalabels is enabled on this project,
      // so without returning "" the raw values will be shown for each data point
      formatter: () => "",
      align: "end",
      anchor: "end",
      color: "#333333",
      font: {
        weight: 700
      }
    }
  }
};

const breakevenCosts = (car, get, yearsOfOwnership, insuranceData,maintenanceData) => {
  return yearsOfOwnership.map(year => {
    switch (year) {
      case 0:
        return parseInt(VehicleCost.afterIncentives(car));

      case yearsOfOwnership.length - 1:
        return calcTotalCostOfOwnership(
          car,
          get('purchaseMethod'),
          get('monthsOfOwnership'),
          get("milesDrivenAnnually"),
          get("interestRateAsBasisPoints"),
          get("electricMilesPortionForPhev"),
          get("gasolinePriceInCentsPerGal"),
          get("includeResaleValue"),
          process.env.REACT_APP_SLIDE_SALES_TAX ? get("salesTax") : SALES_TAX.value,
          process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING ? get("costPublicCharging") : 0,
          process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING ? get("publicChargingPercentage") : 0,
          process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE ? get("electricityRate") : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value,
          process.env.REACT_APP_DYNAMIC_INSURANCE_COST ? get("municipality") : null,
          insuranceData,
          maintenanceData
        ).summed.totalLastYear;

      default:
        return calcTotalCostOfOwnership(
          car,
          "cash",
          year * 12,
          get("milesDrivenAnnually"),
          get("interestRateAsBasisPoints"),
          get("electricMilesPortionForPhev"),
          get("gasolinePriceInCentsPerGal"),
          false, // do not include resale value in intermediate years
          process.env.REACT_APP_SLIDE_SALES_TAX ? get("salesTax") : SALES_TAX.value,
          process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING ? get("costPublicCharging") : 0,
          process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING ? get("publicChargingPercentage") : 0,
          process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE ? get("electricityRate") : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value,
          process.env.REACT_APP_DYNAMIC_INSURANCE_COST ? get("municipality") : null,
          insuranceData,
          maintenanceData
        ).summed.total;
    }
  });
};

const BreakevenChart = ({
  cars,
  forceUserPrefsPresets,
  onAnimationComplete,
  insuranceData,
  maintenanceData
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();

  if (!cars || cars.length === 0) return null;

  cars = cars.filter((car) => {
    return car !== null;
  });

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  const yearsOfOwnership = times(get("monthsOfOwnership") / 12 + 1, (i) => i);

  const carCosts = cars.map((car) => {
    return breakevenCosts(car, get, yearsOfOwnership,insuranceData[car.handle],  maintenanceData[car.handle]);
  });
  const carTotalCosts = cars.map((car) => {
    return getCarCostOfOwnership(car, userPrefs, insuranceData[car.handle], maintenanceData[car.handle]);
  });

  const priceChange = (carCosts) => {
    let years = [];
    for (let i = 0; i < carCosts[0].length; i++) {
      if (carCosts[0][i] < carCosts[1][i]) {
        years.push(i)
      }
    }
    if (years.length === carCosts[0].length) {
        return -1
      }
    else if (years.length === 0) {
        return -2
    }
    return years[0];
  };

  const renderSwitch = (param) => {
    switch(param) {
      case -2:
        return (
          <FormattedMessage
          id="graph.costToBreakdown.subTitle"
          defaultMessage="The {car1} is not currently cheaper to own than your existing vehicle"
          description="Cost to Breakdown Subtitle"
          values={{
            car1: FormatCarName(cars[0])
          }}
        />);
      case -1:
        return (
          <FormattedMessage
            id="graph.costToBreakdown.subTitleAlwaysCheaper"
            defaultMessage="The {car1} is currently cheaper to own than your existing vehicle"
            description="Cost to Breakdown Subtitle"
            values={{
              car1: FormatCarName(cars[0]),
            }}
          />
        );
      default:
        return (
          <FormattedMessage
            id="graph.costToBreakdown.subTitleCheaper"
            defaultMessage="The {car1} becomes cheaper after {time}"
            description="Cost to Breakdown Subtitle"
            values={{
              car1: FormatCarName(cars[0]),
              time:
                priceChange(carCosts) === 1
                  ? intl.formatMessage({
              id: "graph.OneYear",
              defaultMessage: " 1 year",
            })
                  : `${priceChange(carCosts)} ` + intl.formatMessage({
              id: "years",
              defaultMessage: "years",
            })}}
          />
        );
    }
  }
  let subTitle = renderSwitch(priceChange(carCosts));


  let datasets = [
    {
      data: carCosts[1],
      label: FormatCarName(cars[1]),
      borderColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_ELECTRICITY}`,
      pointBackgroundColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_ELECTRICITY}`,
      lineTension: 0,
      fill: false,
    },
    {
      data: carCosts[0],
      label: FormatCarName(cars[0]),
      borderColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_GASOLINE}`,
      backgroundColor: "rgba(0,169,206, 0.5)",
      fill: "-1",
      pointBackgroundColor: `#${process.env.REACT_APP_COMPONENTS_BREAKEVEN_CHART_BAR_COLOR_GASOLINE}`,
      lineTension: 0,
    },
  ];

  if (cars.length === 3) {
    subTitle = (
      <p className="h3 my-3 graph-title">
        <FormattedMessage
          id="graph.costToBreakdown.subTitleThreeCars"
          defaultMessage="Compare the cumulative lifetime cost of {car1}, {car2} and {car3}"
          description="Cost to Breakdown Subtitle 3 Cars"
          values={{
            car1: FormatCarName(cars[0]),
            car2: FormatCarName(cars[1]),
            car3: FormatCarName(cars[2]),
          }}
        />
      </p>
    );
    datasets.push({
      data: carCosts[2],
      label: FormatCarName(cars[2]),
      borderColor: `#0000ff`,
      backgroundColor: "transparent",
      lineTension: 0,
      borderDash: [10, 10],
    });
  }

  const title = (
    <h3>
      <div className="graph-title">
        {subTitle}
      </div>
    </h3>
  );

  const chartData = {
    labels: yearsOfOwnership,
    datasets: datasets,
  };

  const chartOptions = Object.assign({}, CHART_JS_OPTIONS, {
    animation: {
      onComplete: onAnimationComplete,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  });
  const mathValue = (carCosts[1][carCosts[1].length - 1] - carCosts[0][carCosts[0].length - 1]) ?? carTotalCosts[1].summed.total - carTotalCosts[0].summed.total
  return (
    <div className="BreakevenChart input-well text-center">
      {title}
      <div className="line-box chart-container d-none d-lg-block d-xl-block">
        <div className="chart-info">
          <Line
            data={chartData}
            type="line"
            options={chartOptions}
            height={250}
          />
        </div>
        <div className="savings-info">
          {"$" +
            `${Math.floor(
              mathValue
            )
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` +
            intl.formatMessage({
              id: "graph.savings",
              defaultMessage: " in savings",
            })}
        </div>
      </div>

      <ShowHideButton
        buttonText={intl.formatMessage({
          id: "graph.graphValues",
          defaultMessage: "Graph Values",
        })}
        displayChildrenOnlyOnTablet
      >
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">{FormatCarName(cars[0])}</th>
                  <th scope="col">{FormatCarName(cars[1])}</th>
                  {cars.length === 3 ? (
                    <th scope="col">{FormatCarName(cars[2])}</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {yearsOfOwnership.map((year) => {
                  return (
                    <tr key={year}>
                      <th scope="row">
                        {intl.formatMessage({
                          id: "graph.year",
                          defaultMessage: "Year",
                        })}{" "}
                        {year}
                      </th>
                      <td>{FormatAsDollars(carCosts[0][year])}</td>
                      <td>{FormatAsDollars(carCosts[1][year])}</td>
                      {cars.length === 3 ? (
                        <td>{FormatAsDollars(carCosts[2][year])}</td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <p className="text-left" style={{ color: "#666" }}>
              <i>
                {intl.formatMessage({
                  id: "graph.costToBreakdown.note",
                  defaultMessage:
                    "Note: Breakeven chart assumes the vehicles are purchased in cash.",
                })}
              </i>
            </p>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

export default BreakevenChart;

BreakevenChart.propTypes = {
  car: PropTypes.object,
  comparedCar: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  onAnimationComplete: PropTypes.func
};
